<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="message" label-position="top" :model="data">
      <el-form-item label="Текст сообщения">
        <el-input v-model="data.text" type="textarea" :rows="2" placeholder="Текст сообщения..." />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Дата сообшения">
            <el-date-picker
              v-model="data.date"
              type="date"
              placeholder="Выберите дату"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="time" label="Время сообшения">
            <el-time-select
              v-model="data.time"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="Выберите время"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Загрузить скриншоты переписки" prop="image">
        <el-upload
          list-type="picture-card"
          :http-request="uploadFile"
          action=""
          :file-list="data.images"
          :on-remove="handleRemove"
          :on-preview="handlePictureCardPreview"
        >
          <i class="el-icon-plus" />
        </el-upload>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="sendData"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MessagesModal',
  props: {
    title: {
      type: String,
      default: 'Добавить переписку',
    },
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    defaultData: {
      text: '',
      images: [],
    },
    formRules: {
      time: [{ required: true, message: 'Укажите время', trigger: 'blur' }],
    },
    dialogImageUrl: '',
    dialogVisible: false,
  }),
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    data: {
      get() {
        return this.message ? this.message : this.defaultData;
      },
      set(value) {
        this.$emit('update:message', value);
      },
    },
  },
  methods: {
    async sendData() {
      this.$refs.message.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        const action = this.data.id ? 'UPDATE_CLIENT_MESSAGES' : 'STORE_CLIENT_MESSAGES';
        try {
          await this.$store.dispatch(action, this.data);
          this.$notify({
            title: 'Успешно',
            message: 'Сообщение успешно добавлено',
            duration: 1500,
            type: 'success',
          });
        } catch (error) {
          this.$notify.error({
            title: 'Ошибка',
            message: 'Ошибка добавления',
            duration: 1500,
          });
        }
        this.loading = false;
        await this.closeModal();
      });
    },
    async uploadFile(e) {
      const { file } = e;
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.$store.dispatch('UPLOAD_FILE', formData);
      this.data.images.push({ url: `${process.env.VUE_APP_API_HOST_IMG}${response.path}` });
    },
    handleRemove(file) {
      this.data.images = this.data.images.filter((image) => image.id !== file.id);
    },
    async closeModal() {
      this.data = null;
      this.visible = false;
      await this.$store.dispatch('FETCH_CLIENT_MESSAGES');
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
