<template>
  <div class="mt-4">
    <el-row v-if="client" :gutter="20" class="mb-6">
      <el-col :span="6">
        <div class="is-flex is-align-items-center">
          <span class="has-text-info mr-2">Мессенджер: </span>
          <el-link
            class="edit-link-primary"
            type="primary"
            icon="el-icon-edit"
            @click="editField('messenger')"
          />
        </div>
        <el-select v-model="client.messenger" size="small" style="width: 100%" placeholder="Viber">
          <el-option label="WhatsApp" value="WhatsApp" />
          <el-option label="Instagram" value="Instagram" />
          <el-option label="Vk" value="Vk" />
          <el-option label="Telegram" value="Telegram" />
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="is-flex is-align-items-center">
          <span class="has-text-info mr-2">Логин: </span>
          <el-link
            class="edit-link-primary"
            type="primary"
            icon="el-icon-edit"
            @click="editField('messenger_login')"
          />
        </div>
        <span v-if="!fields.messenger_login" class="mt-2" @dblclick="editField('messenger_login')">
          {{ client.messenger_login }}
        </span>
        <div v-else class="is-flex mt-2">
          <el-input v-model="client.messenger_login" size="small" autosize />
          <el-link
            type="success"
            class="ml-2 edit-link-success"
            size="mini"
            icon="el-icon-check"
            :underline="false"
            @click="sendUpdateData('messenger_login')"
          />
        </div>
      </el-col>
      <el-col :span="12" class="is-flex is-justify-content-flex-end mb-3">
        <el-button type="primary" icon="el-icon-plus" @click="modals.create.show = true">
          Добавить переписку
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <general-table
          :data="messages"
          :loading="loading"
          :fields="fieldsTableMessage"
          :rules="rules"
          @edit="handleEdit"
          @delete="handleDelete"
        />
      </el-col>
    </el-row>
    <messages-modal :show.sync="modals.create.show" :message.sync="message" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import GeneralTable from '../../../components/common/GeneralTable.vue';
import MessagesModal from './messages/MessagesModal.vue';

export default {
  name: 'Messages',
  components: { GeneralTable, MessagesModal },
  data: () => ({
    fields: {
      messenger: false,
      messenger_login: false,
    },
    loading: true,
    fieldsTableMessage: [
      { label: '#', value: 'id' },
      { label: 'Текст', value: 'text' },
      { label: 'Дата', value: 'date_format' },
      { label: 'Менеджер', value: 'manager.name' },
    ],
    modals: {
      create: {
        show: false,
      },
    },
    message: null,
  }),
  computed: {
    ...mapGetters({ client: 'CLIENT', messages: 'MESSAGES' }),
    ...mapState({
      isAvailable: (state) => ['manager'].includes(state.authState.authState.role),
    }),
    rules() {
      return this.isAvailable
        ? [
            {
              value: 'edit',
              type: 'primary',
              icon: 'el-icon-edit',
            },
          ]
        : [
            {
              value: 'delete',
              type: 'danger',
              icon: 'el-icon-delete',
            },
            {
              value: 'edit',
              type: 'primary',
              icon: 'el-icon-edit',
            },
          ];
    },
  },
  async mounted() {
    if (!this.client) {
      await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
    }
    await this.$store.dispatch('FETCH_CLIENT_MESSAGES');
    this.loading = false;
  },
  methods: {
    editField(field) {
      this.fields[field] = !this.fields[field];
    },
    async sendUpdateData(field) {
      await this.$store.dispatch('UPDATE_CLIENT', this.client);
      this.editField(field);
    },
    handleEdit(message) {
      this.message = message;
      this.modals.create.show = true;
    },
    async handleDelete(message) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_CLIENT_MESSAGES', message.id);
            this.$notify({
              title: 'Успешно',
              message: 'Переписка успешно удалена',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
          this.loading = false;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
</style>
